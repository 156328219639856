<template>
  <c-card :title="`Daftar Aktivitas `">
    <pagination-table :sortKey.sync="sortKey" :sortDesc.sync="sortDesc" :fields="fields" endpoint="v1/users/userlogs" :queryParams="{username:auth.username}">
      <template v-slot:createdAt="{item:{createdAt}}">
        {{createdAt | fulldate}}
      </template>
    </pagination-table>
  </c-card>
</template>

<script>
import PaginationTable from '@/utils/components/PaginationTable.vue'
import CCard from '@/utils/components/CCard.vue'

export default {
  components:{
    PaginationTable,
    CCard,
  },
  data(){
    return {
      fields:[
        {key:'activity',label:'Aktivitas'},
        {key:'createdAt',label:'Log',sortable:true}
      ],
      sortKey:'createdAt',
      sortDesc:true
    }
  }
}
</script>

<style>

</style>
<template>
  <div>
    <div class="d-flex justify-content-between mb-1">
      <div>
        <small class="padding">Tampilkan </small>
        <label>
          <b-form-select
            @input="getData()"
            v-model="pageLength"
            :options="lengthArray"
          />
        </label>
        <small class="padding"> Entri</small>
      </div>
      <div class="w-25">
        <b-input-group>
          <b-form-input @input="getData()" v-model="search" type="search" placeholder="Cari..."></b-form-input>
          <b-input-group-append is-text>
            <feather-icon icon="SearchIcon"></feather-icon>
          </b-input-group-append>
        </b-input-group>
      </div>
    </div>
    <div v-if="isloading">
      <b-skeleton-wrapper spinner-variant="primary" :opacity="1" variant="secondary">
        <b-skeleton width="55%"></b-skeleton>
        <b-skeleton width="65%"></b-skeleton>
        <b-skeleton width="75%"></b-skeleton>
        <b-skeleton width="35%"></b-skeleton>
        <b-skeleton width="70%"></b-skeleton>
        <b-skeleton width="60%"></b-skeleton>
      </b-skeleton-wrapper>
    </div>
    <b-table v-else ref="datatables" class="datatables" :sort-by.sync="sortKeyValue" :sort-desc.sync="sortDescValue" no-local-sorting show-empty responsive striped :fields="fields" :items="items" v-on="$listeners" v-bind="attrs">
      <template v-for="slot in Object.keys($scopedSlots)" v-slot:[toCellName(slot)]="props">
        <slot v-bind="props" :name="slot" />
      </template>
      <template #empty>
        <h6>Tidak ada data.</h6>
      </template>
    </b-table>
    <div class="d-flex justify-content-between">
      <b-skeleton-wrapper :loading="isloading" style="width:100%;">
        <template #loading>
          <b-skeleton width="100%"></b-skeleton>
        </template>
        <span class="padding small">Menampilkan {{fromQty}} sampai {{toQty}} dari {{total}} data.</span>
        <b-pagination v-model="page" :per-page="pageLength" :total-rows="total" ></b-pagination>
      </b-skeleton-wrapper>
    </div>
  </div>
</template>

<script>
import {BTable,BFormSelect,BInputGroup,BFormInput,BInputGroupAppend,BPagination,BSkeletonWrapper,BSkeleton} from 'bootstrap-vue'
import debounce from 'lodash/debounce'

export default {
  name:'CursorTable',
  props:{
    fields:{
      type:Array
    },
    endpoint:String,
    queryParams:Object,
    sortKey:String,
    sortDesc:Boolean
  },
  components:{
    BTable,
    BFormSelect,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BPagination,
    BSkeletonWrapper,
    BSkeleton,
  },
  data(){
    const lengthArray = [
      10,
      25,
      50,
      100
    ]
    return {
      isloading:true,
      items:[],
      pageLength: 10,
      lengthArray,
      search:'',
      total:0,
      page:1,
      total_pages:1,
      sortKeyValue: this.sortKey,
      sortDescValue: this.sortDesc,
    }
  },
  watch:{
    page(){
      const vm = this
      vm.getData()
    },
    sortKeyValue(value){
      const vm = this
      vm.page = 1
      vm.getData()
      vm.$emit('update:sortKey',value)
    },
    sortDescValue(value){
      const vm = this
      vm.page = 1
      vm.getData()
      vm.$emit('update:sortDesc',value)
    },
    pageLength(){
      const vm = this
      vm.page = 1
      vm.getData()
    }
  },
  computed:{
    fromQty(){
      const vm = this
      if(vm.page==1) return 1
      else return ((vm.page-1)*vm.pageLength)+1
    },
    toQty(){
      const vm = this
      if(vm.total_pages==vm.page) return vm.page*vm.pageLength-(vm.pageLength-vm.items.length)
      else return vm.page*vm.pageLength
    },
    attrs(){
      let attrs = JSON.parse(JSON.stringify(this.$attrs))
      delete attrs['no-local-sorting']
      delete attrs['show-empty']
      delete attrs['responsive']
      delete attrs['striped']
      delete attrs['sort-by']
      delete attrs['sort-desc']
      return attrs
    }
  },
  methods:{
    toCellName (slot) {
      return `cell(${slot})`
    },
    async getData(){
      this.onLoad(this)
    },
    onLoad: debounce((vm) => {
      vm.isloading = true
      let endpoint = vm.endpoint
      const params = {
        search: vm.search,
        page: vm.page,
        per_page: vm.pageLength,
        sort: vm.sortKey,
        order: vm.sortDesc ? 'desc':'asc',
        ...vm.queryParams
      }
      vm.$http.get(endpoint,{params:params}).then(e => {
        const {data,total,total_pages} = e.data
        vm.items = data
        vm.total = total
        vm.total_pages = total_pages
        vm.isloading = false
      }).catch(()=>{
        vm.isloading = false
      })
    },200),
    refresh(){
      this.getData()
    },
    getItems(){
      return this.items
    }
  },
  created(){
    this.getData()
  }
}
</script>

<style scoped lang="scss">
  .datatables {
    border-radius: 7px;
  }
  .padding {
    padding-left: 5px;
    padding-right: 5px;
  }
</style>